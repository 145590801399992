.projectsComponent {
    display: inline-flex;
    width: 90%;
    margin: 1rem;
    flex-direction: column;
    align-items: center;
    background-image: url(./codeblur.png);
    border-radius: 2rem;
    padding: 0 2rem 2rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.projectsHeader {
    color: white;
}

.projectTitle {
    text-align: center;
}

.descriptionSection {
    width: 30rem;
    background-color: #ffffff8c;
    padding: 0.5rem;
    border-radius: 1rem;
}

.allProjects {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.5rem 0;
}

.projectSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
}

.additionalProjectInfo {
    text-align: center;
    font-weight: bold;
    margin: 0;
    width: 25rem;
}

.lessonsLearned {
    text-align: center;
}

.project {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    border-radius: 1rem;
    margin: 0.5rem;
    border: 3px solid black;
    background-color: #ffffffa1;
    -webkit-transition: box-shadow 200ms ease,-webkit-transform 200ms ease;
    transition: box-shadow 200ms ease,transform 200ms ease, background-color 200ms;
    box-shadow: 0px 0px 0 var(--color-navy);
}

.project:hover {
    -webkit-transform: translate(4px, -4px);
    -moz-transform: translate(4px, -4px);
    -ms-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
    background-color: #d1f8f9d5;
    box-shadow: -8px 8px black;
}

.project h2 {
    margin: 0.5rem 0;
}

.project ul {
    align-self: flex-start;
    justify-self: flex-end;
}

.projectImg {
    margin: 0.5rem;
    max-width: 450px;
}

.projectsTextBlock {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
}