.portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("./greyscalesplash.png");
    height: 100%;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contactMe {
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    margin: 1rem;
    max-width: 90%;
    background-color: #ffffffa1;
}

.contactMe h2 {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 10px;
    }
}