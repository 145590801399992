.educationComponent {
    margin: 1rem;
    padding: 0 2rem 2rem;
    border-radius: 2rem;
    width: 90%;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-image: url("./images/stackoverflowblur.png");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.educationSection {
    display: inline-flex;
    align-items: stretch;
}

.educationList {
    background-color: #ffffffa1;
    padding: 2rem;
    margin: 1rem;
    border-radius: 2rem;
}

.section2 {
    margin: 0 1rem;
}

.educationimg {
    width: 5rem;
    height: auto;
    margin: 0.2rem;
}

.s3images {
    width: 11rem;
    height: 11rem;
    display: flex;
    flex-direction: column;
}

.logosRow {
    display: flex;
    align-items: center;
}

.programLogos {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.programLogos img {
    width: 5rem;
    height: auto;
}

.fullsizeLogo {
    width: 11rem;
    height: auto;
}

.section1 {
    display: flex;
    align-items: center;
}

.section3 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.section3 .fullsizeLogo, .section3 .programLogos {
    margin: 1rem;
}

.section3 .programLogos {
    flex-direction: row;
    margin: 0;
}

.section3 .fullsizeLogo {
    width: 9rem;
}

.mobileResponse {
    display: none;
}

@media only screen and (max-width: 1060px) {
    .section1 {
        display: none;
    }

    .mobileResponse {
        display: flex;
    }
}