.tile {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    border: 1px solid black;
    margin: 0;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

.tile:hover {
    border: 1px solid yellowgreen;
  }

.minesweeperGameGrid {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid black;
}

.timer {
    font-family: 'Orbitron', sans-serif;
}

.remainingBombs {
    display: inline;
    font-family: 'Orbitron', sans-serif;
    padding: .25rem 1rem;
    background-color: black;
    border: 1px solid black;
    border-radius: 0.33rem;
    color: red;
}

.minesweeperGame {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    padding: 1.5rem;
    border: 3px solid black;
    border-radius: 1rem;
    background-color: #ededed;
}

.gameBoard {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
}

.gameBoard div {
    width: 33%;
    display: flex;
    justify-content: center;
}

.minesweeperLogo {
    width: 3.5rem;
}