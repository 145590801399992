.intro {
    display: inline-flex;
    align-items: stretch;
    background-color: #d1f8f9a6;
    padding: 0.5rem;
    border-radius: 1rem;
    margin: 0.5rem;
}

.introSection {
    margin: 0.5rem;
    -webkit-transition: box-shadow 200ms ease,-webkit-transform 200ms ease;
    transition: box-shadow 200ms ease,transform 200ms ease;
    box-shadow: 0px 0px 0 var(--color-navy);
}

.introSection:hover {
    -webkit-transform: translate(4px, -4px);
    -moz-transform: translate(4px, -4px);
    -ms-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
    box-shadow: -8px 8px black;
}

.introLogoContainer {
    display: flex;
    align-items: center;
    background-color: white;
    border: 3px solid black;
    border-radius: 1rem;
    padding: 0.75rem;
}

.introLogo {
    height: 9rem;
    width: auto;
}

.body {
    display: inline-flex;
    flex-flow: column;
    border: 3px solid black;
    border-radius: 1rem;
    padding: 0.75rem;
    background-color: white;
    overflow: hidden;
    white-space: nowrap;
}

.introLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.body h2 {
    display: flex;
    margin: 0.66rem 0;
    align-items: center;
}

.body img {
    width: 2.33rem;
    height: auto;
    padding-right: 0.33rem;
}

.linksCard {
    display: inline-flex;
    flex-flow: column;
    justify-content: center;
    border: 3px solid black;
    border-radius: 1rem;
    padding: 0 0.25rem;
    background-color: white;
    overflow: hidden;
    white-space: nowrap;
}

.linksLogo {
    width: 1.5rem;
    margin-right: 0.5rem;
}

.linksCard div {
    margin: 0;
    font-size: 1.25rem;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 0.75rem;
    transition: background-color 0.25s;
}

.linksCard div a {
    text-decoration: none;
    color: black;
    border-radius: 0.75rem;
    font-weight: bold;
}

.personalLink1:hover {
    background-color: #32ff003d;
}

.personalLink2:hover {
    background-color: #ddff005c;
}

.personalLink3:hover {
    background-color: #00ffe142;
}

.personalLink4:hover {
    background-color: #ee00ff24;
}

@media only screen and (max-width: 1060px) {
    .introLogoContainer {
        display: none;
    }    
}

@media only screen and (max-width: 770px) {
    .linksCard {
        display: none;
    }
}